import React from 'react';
import '../styles/Skills.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import html from '../img/skills/html.svg';
import css from '../img/skills/css.svg';
import js from '../img/skills/javascript.svg';
import react from '../img/skills/react.svg';
import ts from '../img/skills/typescript.svg';
import bootstrap from '../img/skills/bootstrap.svg';
import vite from '../img/skills/vite.svg';
import jest from '../img/skills/jest.svg';
import mocha from '../img/skills/mocha.svg';
import chai from '../img/skills/chai.png';
import sinon from '../img/skills/sinon.png';
import sql from '../img/skills/sql.png';
import express from '../img/skills/express.png';
import sequelize from '../img/skills/sequelize.svg';
import rtl from '../img/skills/rtl.png';
import redux from '../img/skills/redux.png';
import python from '../img/skills/python.svg';
import jwt from '../img/skills/jwt.png';
import node from '../img/skills/nodejs.svg';
import docker from '../img/skills/docker.svg';
import pytest from '../img/skills/pytest.svg';
import sass from '../img/skills/sass.svg';

function Skills() {
  const technologyCategories = [
    { 
      title: 'Frontend', 
      items: [
        { image: html, name: 'HTML' },
        { image: css, name: 'CSS' },
        { image: js, name: 'JavaScript' },
        { image: ts, name: 'TypeScript' },
        { image: react, name: 'React' },
        { image: bootstrap, name: 'Bootstrap' },
        { image: vite, name: 'Vite' },
        { image: redux, name: 'Redux' },
        { image: sass, name: 'SASS'}
      ]
    },
    { 
      title: 'Backend', 
      items: [
        { image: node, name: 'Node.js' },
        { image: python, name: 'Python' },
        { image: sql, name: 'SQL' },
        { image: docker, name: 'Docker' },
        { image: express, name: 'Express' },
        { image: sequelize, name: 'Sequelize' },
        { image: jwt, name: 'JWT' }
      ]
    },
    { 
      title: 'Testes', 
      items: [
        { image: jest, name: 'Jest' },
        { image: rtl, name: 'RTL' },
        { image: pytest, name: 'Pytest' },
        { image: mocha, name: 'Mocha' },
        { image: sinon, name: 'Sinon' },
        { image: chai, name: 'Chai' }
      ]
    }
  ];


  const renderTechnologyBoxes = (items) => {
    return items.map((tech, index) => (
      <OverlayTrigger
        key={index}
        placement="top"
        overlay={<Tooltip id={`tooltip-${index}`} className="custom-tooltip">{tech.name}</Tooltip>}
      >
        <div className="technologyBox">
          <img src={tech.image} alt={tech.name} />
        </div>
      </OverlayTrigger>
    ));
  };

  return (
    <section id="sectionSkills">
      <div id="cardMain">
        <div id="skillsItems">
          <h1 id="cardTitle">Habilidades</h1>
          <div id="gradientLine"></div>

          {technologyCategories.map((category, index) => (
            <div key={index}>
              <h3 className="cardSubTitle">{category.title}</h3>
              <div className="technologyGroup">
                {renderTechnologyBoxes(category.items)}
              </div>
            </div>
          ))}
        </div>

        <div id="skillsDescription">
          <h2 id="h2Card">Desenvolvimento Eficiente de Front-end a Back-end!</h2>
          <br />
          <p className="pCard">
            Com uma bagagem sólida em desenvolvimento full-stack, 
            sou capaz de criar aplicações web complexas e eficientes. No front-end, 
            domino React.js, desde a gestão de estado e eventos até a implementação de rotas e Redux para gerenciamento avançado de estados. 
            Além disso, utilizo metodologias ágeis para um desenvolvimento dinâmico e eficaz.
          </p>
          <br />
          <p className="pCard">
            No back-end, mergulho em Docker para facilitar a implementação, 
            combinando-o com Node.js e Express para construir APIs robustas e escaláveis. 
            Tenho experiência em bancos de dados SQL e NoSQL, aplicando TDD com ferramentas como Mocha, 
            Chai e Sinon para garantir a qualidade do código. Utilizo princípios sólidos de desenvolvimento, 
            como SOLID e POO, e estou habituado a realizar deploys e integrar CI/CD em meus projetos.
          </p>
          <br />
          <p className="pCard">
            Além disso, minha jornada não se limita apenas a JavaScript, 
            estendendo-se ao Python para implementações de POO, 
            algoritmos complexos, raspagem de dados e resolução de problemas diversos.
          </p>
        </div>

      </div>
    </section>
  )
}

export default Skills;
