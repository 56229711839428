import React from 'react'
import linkedin from '../img/icons/linkedin.png';
import github from '../img/icons/github.png';
import email from '../img/icons/email.png';
import '../styles/Contact.css'

function Contact() {
  return (
    <>
      <footer id="sectionContact">
        <div className="contactBox">
            <h2>Informações Pessoais</h2>
            <p>Nome: Henrique Gomes Teixeira</p>
            <p>Idade: 19 anos</p>
            <p>Nacionalidade: Brasileiro</p>
            <p>Cidade: Porto Velho</p>
            <p>Estado: Rôdonia</p>
            <p>Formação Academica: Engenharia de Software</p>
          </div>
        <div className="contactBox2">
          <h2>Saiba Mais, Vamos Conversar!</h2>
          <a id="emailLink" href="mailto:henriquegomesteixeiradev@gmail.com">henriquegomesteixeiradev@gmail.com</a>
          <div id="contactLinks">
            <a
              href="https://www.linkedin.com/in/henriquegomesteixeira/"
              target="_blank"
              rel="noopener noreferrer"
              className="linksBox"
            >
              <img src={ linkedin } alt="Linkedin" />
            </a>
            <a
              href="https://github.com/henriquegomesteixeira"
              target="_blank"
              rel="noopener noreferrer"
              className="linksBox"
            >
              <img src={ github } alt="GitHub" />
            </a>
            <a
              href="mailto:henriquegomesteixeiradev@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              className="linksBox"
            >
              <img src={ email } alt="Email" />
            </a>
          </div>
        </div>

      </footer>
      <div id="rights">
        <p>Construído com React</p>
        <p>© 2023 Todos os direitos reservados</p>
      </div>
    </>
  )
}

export default Contact
