import React from 'react';
import { motion } from 'framer-motion';
import '../styles/Home.css';
import '../styles/Button.css';

function Home() {
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 3 } },
  };
  
  return (
    <motion.section
      id="sectionHome"
      initial="hidden"
      animate="visible"
      variants={ fadeInVariants }
    >
      <h3 id="helloText">OLÁ, EU SOU HENRIQUE!</h3>

      <h1 id="mainTitle">Transformando ideias em realidade digital</h1>

      <p className="textHome" id="introText">Sou engenheiro de software especializado em desenvolvimento web full-stack. </p>
      <p className="textHome">Combinando código e design, transformo conceitos em experiências excepcionais!</p>

      <a
        href="https://www.linkedin.com/in/henriquegomesteixeira/"
        target="_blank"
        rel="noopener noreferrer"
        className="uiverse"
        id="btn-contact"
      >
        <div className="wrapper">
          <span>Entrar em contato</span>
          <div className="circle circle-12"></div>
          <div className="circle circle-11"></div>
          <div className="circle circle-10"></div>
          <div className="circle circle-9"></div>
          <div className="circle circle-8"></div>
          <div className="circle circle-7"></div>
          <div className="circle circle-6"></div>
          <div className="circle circle-5"></div>
          <div className="circle circle-4"></div>
          <div className="circle circle-3"></div>
          <div className="circle circle-2"></div>
          <div className="circle circle-1"></div>
        </div>
      </a>
    </motion.section>
  )
}

export default Home;
