import React, { useState } from 'react';
import logo from '../img/icons/logoHenrique.png';
import '../styles/Header.css';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header id="header">
      <a href="#sectionHome" id="logo">
        <img id="logo-img" src={logo} alt="logo" />
      </a>

      <nav id="nav" className={menuOpen ? 'active' : ''}>
        <button id="menuMobile" onClick={toggleMenu} />

        <ul id="nav-list">
          <a href="#sectionSkills" onClick={closeMenu}>
            Habilidades
          </a>
          <a href="#sectionProjects" onClick={closeMenu}>
            Projetos
          </a>
          <a href="#sectionStudies" onClick={closeMenu}>
            Estudos
          </a>
          <a href="#sectionContact" onClick={closeMenu}>
            Contato
          </a>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
