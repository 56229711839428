import React from 'react';
import '../styles/Projects.css';

import Wallet from '../img/projects/Wallet.png';
import PixelsArt from '../img/projects/PixelsArt.png';
import Calculator from '../img/projects/Calculadora.png';
import WeatherForecast from '../img/projects/WeatherForecast.png';
import TodoList from '../img/projects/TodoList.png';

function Projects() {
  return (
    <section id="sectionProjects">
      <h4 id="titleSectionProjects">Projects</h4>
      <div id="gradientLine2"></div>
      <br/>
      <p id="descriptionSectionProjects">
        Tenho estado envolvido em projetos que visam solidificar meus conhecimentos. 
        Quer dar uma olhada?
      </p>

      <div className="containerProject">
        <p>Aplicação Full-Stack</p>
        <h3>Gerenciador de tarefas</h3>
        <h6>ToDoList APP</h6>
        <div>
          <a
            href="https://github.com/henriquegomesteixeira/Todolist-Full-Stack"
            target="_blank"
            rel="noopener noreferrer"
            type="button"
            className="GitHub btn GitHubBack"
          >
            GitHub
          </a>
        </div>

        <ul className="projectTechnologies">
          <li>Node.jst</li>
          <li>Express</li>
          <li>MySQL</li>
          <li>React</li>
          <li>SASS</li>
          <li>Mocha</li>
          <li>Chai</li>
          <li>Sinon</li>
        </ul>
        
        <div className="containerLinks">
          <div className="imageContainer">
            <a href="https://github.com/henriquegomesteixeira/Todolist-Full-Stack" target="_blank" rel="noopener noreferrer">
              <img src={TodoList} alt="ToDoList" className="projectImage" />
              <div className="imageText">
                Visualizar TodoList Full-Stack
              </div>
            </a>
          </div>
        </div>
      </div>
      
      <div className="containerProject">
        <p>Aplicação Front-End</p>
        <h3>Aplicativo de Previsão do Tempo</h3>
        <h6>WEATHER FORESCAST APP</h6>
        <div>
          <a
            href="https://weather-forecast-app-henrique-gomes.vercel.app/"
            target="_blank"
            rel="noopener noreferrer"
            className="btn Deploy"
          >
            Visualizar
          </a>
          <a
            href="https://github.com/henriquegomesteixeira/weather-forecast-app"
            target="_blank"
            rel="noopener noreferrer"
            type="button"
            className="btn GitHub"
          >
            GitHub
          </a>
        </div>

        <ul className="projectTechnologies">
          <li>JavaScript</li>
          <li>CSS</li>
          <li>HTML</li>
          <li>Integração de API</li>
        </ul>
        
        <div className="containerLinks">
          <div className="imageContainer">
            <a href="https://weather-forecast-app-henrique-gomes.vercel.app/" target="_blank" rel="noopener noreferrer">
              <img src={WeatherForecast} alt="WeatherForecast" className="projectImage" />
              <div className="imageText">
                Visualizar Weather Forecast App
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="containerProject">
        <p>Aplicação Front-End</p>
        <h3>Gerenciador de Despesas</h3>
        <h6>TRYBE WALLET</h6>
        <div>
          <a
            href="https://trybe-wallet-smoky.vercel.app/"
            target="_blank"
            rel="noopener noreferrer"
            className="btn Deploy Github"
          >
            Visualizar
          </a>
          <a
            href="https://github.com/henriquegomesteixeira/trybe-wallet"
            target="_blank"
            rel="noopener noreferrer"
            type="button"
            className="btn GitHub"
          >
            GitHub
          </a>
        </div>

        <ul className="projectTechnologies">
          <li>React</li>
          <li>Redux</li>
          <li>Router</li>
          <li>React Testing Library</li>
          <li>Integração de API</li>
        </ul>
        
        <div className="containerLinks">
          <div className="imageContainer">
            <a href="https://trybe-wallet-smoky.vercel.app/" target="_blank" rel="noopener noreferrer">
              <img src={ Wallet } alt="Trybe Wallet" className="projectImage" />
            </a>
            <div className="imageText">
              Visualizar Trybe Wallet
            </div>
          </div>
        </div>
      </div>
      <div className="containerProject">
        <p>Aplicação Front-End</p>
        <h3>Máquina de Cálculos</h3>
        <h6>CALCULADORA MACOS</h6>
        <div>
          <a
            href="https://calculadora-roan-seven.vercel.app/"
            target="_blank"
            rel="noopener noreferrer"
            className="btn Deploy"
          >
            Visualizar
          </a>
          <a
            href="https://github.com/henriquegomesteixeira/Calculadora-do-macOS"
            target="_blank"
            rel="noopener noreferrer"
            type="button"
            className="GitHub btn"
          >
            GitHub
          </a>
        </div>

        <ul className="projectTechnologies">
          <li>JavaScript</li>
          <li>HTML</li>
          <li>CSS</li>
        </ul>
        
        <div className="containerLinks">
          <div className="imageContainer">
            <a href="https://calculadora-roan-seven.vercel.app/" target="_blank" rel="noopener noreferrer">
              <img src={ Calculator } alt="Calculadora MacOs" className="projectImage" />
            </a>
            <div className="imageText">
              Visualizar Calculadora MacOs
            </div>
          </div>
        </div>
      </div>
      <div className="containerProject">
        <p>Aplicação Front-End</p>
        <h3>Aplicação de Arte em Pixel</h3>
        <h6>PIXELS PAINTER</h6>
        <div>
          <a
            href="https://pixel-painter-gamma.vercel.app/"
            target="_blank"
            rel="noopener noreferrer"
            className="btn Deploy"
          >
            Visualizar
          </a>
          <a
            href="https://github.com/henriquegomesteixeira/Pixel-painter"
            target="_blank"
            rel="noopener noreferrer"
            type="button"
            className="GitHub btn"
          >
            GitHub
          </a>
        </div>

        <ul className="projectTechnologies">
          <li>JavaScript</li>
          <li>DOM</li>
          <li>HTML</li>
          <li>CSS</li>
        </ul>
        
        <div className="containerLinks">
          <div className="imageContainer">
            <a href="https://pixel-painter-gamma.vercel.app/" target="_blank" rel="noopener noreferrer">
              <img src={ PixelsArt } alt="Pixels Art" className="projectImage" />
            </a>
            <div className="imageText">
              Visualizar Pixels Art
            </div>
          </div>
        </div>
      </div>
      <div className="containerProject containerBack">
        <p>Aplicação Back-End</p>
        <h3>Simulador de Fantasia POO</h3>
        <h6>TRYBERS AND DRAGONS</h6>
        <div>
          <a
            href="https://github.com/henriquegomesteixeira/trybers-and-dragons"
            target="_blank"
            rel="noopener noreferrer"
            type="button"
            className="GitHub btn GitHubBack"
          >
            GitHub
          </a>
        </div>

        <ul className="projectTechnologies">
          <li>TypeScript</li>
          <li>POO</li>
          <li>SOLID</li>
        </ul>

      </div>
      <div className="containerProject containerBack">
        <p>Aplicação Back-End</p>
        <h3>API de Gerenciamento de Palestrantes</h3>
        <h6>TALKER MANAGER</h6>
        <div>
          <a
            href="https://github.com/henriquegomesteixeira/Talker-Manager"
            target="_blank"
            rel="noopener noreferrer"
            type="button"
            className="GitHub btn GitHubBack"
          >
            GitHub
          </a>
        </div>

        <ul className="projectTechnologies">
          <li>Node.js</li>
          <li>Express</li>
          <li>Middleware</li>
        </ul>

      </div>
      <div id="moreProjects">
        <a
          href="https://github.com/henriquegomesteixeira"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mais projetos
        </a>
      </div>
    </section>
  )
}

export default Projects;
