import React, { useEffect, useState } from 'react'
import '../styles/Studies.css';
import { useInView } from 'react-intersection-observer';

import cs from '../img/skills/cs.svg';
import angular from '../img/skills/angular.svg';
import next from '../img/skills/next.svg';
import vue from '../img/skills/vue.svg';
import tailwind from '../img/skills/tailwind.svg';
import prisma from '../img/skills/prisma.svg';

function Studies() {
  const [visible, setVisible] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: false, // Remove o triggerOnce
    rootMargin: '-100px 0px',
  });

  useEffect(() => {
    if (inView) {
      setVisible(true);
    } else {
      setVisible(false); // Reseta o estado para falso quando sair do campo de visão
    }
  }, [inView]);

  return (
    <section id="sectionStudies" ref={ref}>
      <h4 id="titleSectionStudies">Estudos</h4>
      <div id="gradientLine2"></div>
      <br/>
      <p id="descriptionSectionStudies">
        Esta trilha de estudo reflete meu compromisso 
        em buscar aprendizado contínuo e aprimorar 
        minhas habilidades técnicas.
      </p>
      <br/>
      <div className={`timeline ${visible ? 'visible' : ''}`}>

        <div className="containerTimeline leftContainer">
          <img src={cs} alt="C#" />
          <div className="textBox">
            <h3>C#</h3>
            <h6>EM ESTUDO</h6>
            <p>
              Linguagem de programação da Microsoft usada 
              principalmente para desenvolvimento de software em .NET.
            </p>
            <span className="leftContainerArrow"></span>
          </div>
        </div>

        <div className="containerTimeline rightContainer">
          <img src={angular} alt="Angular" />
          <div className="textBox">
            <h3>Angular</h3>
            <h6>EM ESTUDO</h6>
            <p>
              Framework TypeScript da Google para construir 
              aplicativos web escaláveis.
            </p>
            <span className="rightContainerArrow"></span>
          </div>
        </div>

        <div className="containerTimeline leftContainer">
          <img src={prisma} alt="Prisma" />
          <div className="textBox">
            <h3>Prisma</h3>
            <h6>ESTUDO EM BREVE</h6>
            <p>
            Ferramenta ORM (Object-Relational Mapping) para banco de dados em Node.js e TypeScript.
            </p>
            <span className="leftContainerArrow"></span>
          </div>
        </div>

        <div className="containerTimeline rightContainer">
          <img src={tailwind} alt="tailwind" />
          <div className="textBox">
            <h3>Tailwind</h3>
            <h6>ESTUDO EM BREVE</h6>
            <p>
              Framework de CSS utilitário que permite a criação 
              rápida e personalizável de interfaces.
            </p>
            <span className="rightContainerArrow"></span>
          </div>
        </div>

        <div className="containerTimeline leftContainer">
          <img src={next} alt="next.js" />
          <div className="textBox">
            <h3>Next</h3>
            <h6>ESTUDO EM BREVE</h6>
            <p>
              Estrutura de aplicativos web React com renderização 
              do lado do servidor e ótimo suporte a SEO.
            </p>
            <span className="leftContainerArrow"></span>
          </div>
        </div>

        <div className="containerTimeline rightContainer">
          <img src={vue} alt="vue.js" />
          <div className="textBox">
            <h3>Vue</h3>
            <h6>ESTUDO EM BREVE</h6>
            <p>
              Framework progressivo para construir interfaces 
              de usuário reativas e flexíveis em JavaScript.
            </p>
            <span className="rightContainerArrow"></span>
          </div>
        </div>

      </div>
    </section>
  )
}

export default Studies
